import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useField
} from "@/components/FormBuilder/Helpers";

export const gbsHelps: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useTextField({
          key: "zipCode",
          label: "Zip Code",
          placeholder: "Zip Code",
          required: false
        }),
        useTextField({
          key: "countyCode",
          label: "County Code",
          placeholder: "County Code",
          required: true
        }),
        useTextField({
          key: "cityCode",
          label: "City Code",
          placeholder: "City Code",
          required: true
        })
      ],
      { layout: "3-col" }
    ),
    ...useFormFieldGroup(
      [
        useTextField({
          key: "county",
          label: "County",
          placeholder: "County",
          required: true
        }),
        useTextField({
          key: "place",
          label: "Place",
          placeholder: "Place",
          required: true
        }),

        useTextField({
          key: "city",
          label: "City",
          placeholder: "City",
          required: true
        }),
        useTextField({
          key: "territoryInfo.ppc",
          label: "PPC",
          placeholder: "PPC",
          required: true
        })
      ],
      { layout: "4-col" }
    )
  ])
];
export const gbsTerritoryInfo: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useField({
          key: `companyTerritories.companyNumber`,
          label: "Company Number",
          type: "select",
          placeholder: "Company Number",
          required: true
        }),
        useTextField({
          key: `companyTerritories.terr`,
          label: "Territory",
          placeholder: "Territory",
          required: true
        }),
        useTextField({
          key: `companyTerritories.seaTerr`,
          label: "Sea Territory",
          placeholder: "Sea Territory"
        }),
        useTextField({
          key: `companyTerritories.windTerr`,
          label: "Wind Territory",
          placeholder: "Wind Territory"
        })
      ],
      { layout: "4-col" }
    )
  ])
];
