
import Vue from "vue";
import { removeEmptyFields } from "@/helpers";

import FormBuilderDataCollector from "@/components/FormBuilder/FormBuilderDataCollector.vue";
import { objectDifference } from "@/helpers";
import * as types from "@/store/mutation-types";
import {
  gbsHelpMapActions,
  gbsHelpMapGetters,
  gbsHelpMapMutations,
  gbsHelpMapState
} from "@/store/modules/gbshelp";

import * as dot from "dot-object";
import {
  companiesMapGetters,
  companiesMapActions
} from "@/store/modules/companies";
import { gbsHelps, gbsTerritoryInfo } from "@/forms/admin/gbsHelps";
import { cloneDeep, get } from "lodash";
import { gbsHelpCompanyTerritoriesDefault } from "@/helpers/defaultObjects";
interface IGBSHelps {
  errorMessage: string;
  successMessage: string;
  isAdmin: true;
  isLoading: boolean;
  validationData: Record<string, any>;
  territoryFormIsValid: boolean;
  loadingText: string;
  resourceNotFound: boolean;
}
export default Vue.extend({
  name: "createGbsHelp",
  data(): IGBSHelps {
    return {
      errorMessage: "",
      isAdmin: true,
      successMessage: "",
      isLoading: false,
      validationData: {},
      territoryFormIsValid: true,
      loadingText: "",
      resourceNotFound: false
    };
  },
  components: {
    FormBuilderDataCollector
  },
  created() {
    this.SET_EDIT({} as any);
    this.getCompanies({});
    if (this.gbsHelpId) {
      this.getGbsHelp(this.gbsHelpId).catch(e => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(e);
      });
    }
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  computed: {
    ...gbsHelpMapGetters(["getEditingGbsHelp", "getGbsHelpById"]),
    ...gbsHelpMapState(["editing", "makingApiRequest"]),
    ...companiesMapGetters(["companies"]),
    gbsHelpId(): string {
      return this.$route.params.id;
    },
    original(): any {
      let gbsHelp = this.getGbsHelpById(this.gbsHelpId) || {};

      if (Object.keys(gbsHelp).length) {
        let gbsHelpTerritoryInfo = gbsHelp.territoryInfo.companyTerritories.map(
          (territory: any) => {
            return { ...gbsHelpCompanyTerritoriesDefault, ...territory };
          }
        );

        return {
          ...gbsHelp,
          territoryInfo: {
            ...gbsHelp.territoryInfo,
            companyTerritories: gbsHelpTerritoryInfo
          }
        };
      }

      return gbsHelp;
    },
    companyNumbers(): { label: string; value: string }[] {
      return this.companies.map(({ companyName, companyNumber }: any) => {
        return {
          label: companyName,
          value: companyNumber
        };
      });
    },
    territoryInfo(): any {
      return get(this.editing, "territoryInfo", {});
    },
    companyTerritories(): any {
      return (
        this.territoryInfo.companyTerritories.map((territory: any) => {
          return { ...gbsHelpCompanyTerritoriesDefault, ...territory };
        }) || []
      );
    },
    updatedFields(): any {
      const edited = this.getEditingGbsHelp;
      return edited && this.original
        ? objectDifference(edited, this.original)
        : {};
    },

    formIsValid(): boolean {
      return (
        !!this.validationData.formIsValid && this.companyTerritories.length
      );
    },
    schema() {
      return gbsHelps;
    },
    gbsTerritoryInfoSchema() {
      return gbsTerritoryInfo;
    },
    disableButton(): boolean {
      return (
        !this.formIsValid ||
        !Object.keys(this.updatedFields).length ||
        !this.territoryFormIsValid
      );
    }
  },
  methods: {
    ...companiesMapActions(["getCompanies"]),
    ...gbsHelpMapActions(["createGbsHelp", "updateGbsHelp", "getGbsHelp"]),
    ...gbsHelpMapMutations(["SET_EDIT", "SET_EDIT_FIELD"]),
    ...gbsHelpMapMutations({ editField: types.SET_EDIT_FIELD }),
    async promptUpdateConfirmation(): Promise<void> {
      try {
        if (this.gbsHelpId) {
          this.$modal.show("gbshelpSingleEditModal");
        } else {
          this.submit();
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    },
    handleTerritoryValidationChanged($event: Record<string, any>) {
      this.territoryFormIsValid = $event.formIsValid;
    },
    addTerritoryInfo() {
      const data = cloneDeep(this.territoryInfo);
      data.companyTerritories.push(gbsHelpCompanyTerritoriesDefault);
      this.SET_EDIT_FIELD({ key: "territoryInfo", value: data });
    },
    editTerritoryInfo(companyTerritories: any, editingTerritoryIndex: number) {
      const addedData = cloneDeep(companyTerritories.companyTerritories);
      const dataToSave: any = dot.object(companyTerritories);
      dataToSave.companyTerritories.isSeaCoast = !!dataToSave.companyTerritories
        .seaTerr;
      const data = cloneDeep(this.territoryInfo);
      data.companyTerritories[editingTerritoryIndex] = {
        ...addedData,
        ...dataToSave.companyTerritories
      };
      this.SET_EDIT_FIELD({ key: "territoryInfo", value: data });
    },
    deleteTerritory(index: number) {
      const data = cloneDeep(this.territoryInfo);
      data.companyTerritories.splice(index, 1);
      this.SET_EDIT_FIELD({ key: "territoryInfo", value: data });
    },
    async submit(): Promise<void> {
      try {
        if (this.formIsValid) {
          this.isLoading = true;
          this.loadingText = "Saving PPC. Please wait...";
          if (!this.gbsHelpId) {
            await this.createGbsHelp(removeEmptyFields(this.updatedFields));
            this.successMessage = "PPC created successfully";
          } else {
            await this.updateGbsHelp({
              id: this.gbsHelpId,
              update: this.updatedFields
            });
            this.successMessage = "PPC updated successfully";
          }

          this.$router.replace("/admin/ppc").catch(() => {});
        } else {
          this.errorMessage = "Please Fill All Required Fields.";
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    async createCallback(): Promise<any> {
      this.$router.push("/admin/ppc").catch(() => {});
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.promptUpdateConfirmation();
          break;
        case "cancelAddingPPC":
          this.createCallback();
          break;
      }
    },
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    }
  }
});
